import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/joeloliveira/Development/WWW/Projects/notificare-website/src/components/layout.js";
import Seo from "../../components/seo";
import HeroPages from "../../components/heroes/hero-pages";
import { Container } from 'react-bootstrap';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Seo title={props.pageContext.frontmatter.title} pathname={props.location.pathname} mdxType="Seo" />
    <HeroPages content={{
      title: props.pageContext.frontmatter.title
    }} theme="primary" mdxType="HeroPages" />
    <div className="mdx-page-wrapper">
      <Container mdxType="Container">
        <h2>{`Responsible Disclosure Policy`}</h2>
        <p>{`At Notificare, we believe that the security of our systems, our network and our products is very important. We pay a lot of attention to this during development and maintenance. However, sometimes vulnerabilities escape detection. We appreciate you notifying us if you find one. We would prefer to hear about it as soon as possible so that we can take measures to protect our customers.`}</p>
        <p>{`This document describes the procedure we have prepared for this.`}</p>
        <h3>{`Reporting`}</h3>
        <p>{`If you believe you’ve found a security issue in our product or service, please notify us as soon as possible by emailing us at `}<a parentName="p" {...{
            "href": "mailto:security@notifica.re"
          }}>{`security@notifica.re`}</a>{`. If possible use our PGP key ID=EDD01C0F (`}<a parentName="p" {...{
            "href": "https://pgp.surfnet.nl/pks/lookup?search=security%40notifica.re&fingerprint=on&op=index"
          }}>{`fingerprint=51BE 4CA7 30B3 7DDD 40E8  6E3A 1BFA 0750 EDD0 1C0F`}</a>{`).`}</p>
        <h3>{`Rules`}</h3>
        <ul>
          <li parentName="ul">{`Do not share information about the security problem with others until the problem is resolved.`}</li>
          <li parentName="ul">{`Provide information about how and when the vulnerability or malfunction occurs. Clearly describe how this problem can be reproduced and provide information about the method used and the time of investigation.`}</li>
          <li parentName="ul">{`Be responsible with the knowledge about the security problem. Do not perform any actions beyond those necessary to demonstrate the security problem. Do not abuse the vulnerability and do not keep confidential data obtained through the vulnerability in the system.`}</li>
          <li parentName="ul">{`Leave your contact details (e-mail address or telephone number) if you want, so that Notificare can contact you about the assessment and progress of the vulnerability solution. We also take anonymous reports seriously.`}</li>
          <li parentName="ul">{`Do not use physical attacks, DDOS attacks, social engineering or hacking tools such as vulnerability scanners.`}</li>
          <li parentName="ul">{`Our responsible disclosure policy is not an invitation to actively scan our company network for vulnerabilities. Our systems are being monitored continuously. As a result, there is a good chance that a scan will be detected and our Security Operation Center (SOC) will investigate it.`}</li>
        </ul>
        <h3>{`How does Notificare handle Responsible Disclosure?`}</h3>
        <p>{`When you report a suspected vulnerability in an IT system, we will deal with this in the following way:`}</p>
        <ul>
          <li parentName="ul">{`You will receive confirmation of receipt from Notificare within three business days after the report.`}</li>
          <li parentName="ul">{`You will receive a response within three business days after the confirmation of receipt containing an assessment of the report and the expected date of resolution. We strive to keep you informed on progress of resolution.`}</li>
          <li parentName="ul">{`Notificare will treat your report confidentially and will not share your information with third parties without your permission, unless this is required by law or by a court order.`}</li>
          <li parentName="ul">{`In communication about the reported problem, we will state your name as the party that discovered the problem, if you wish.`}</li>
          <li parentName="ul">{`It is unfortunately not possible to guarantee in advance that no legal action will be taken against you. We hope to be able to consider each situation individually. We consider ourselves morally obligated to report you if we suspect the weakness or data are being abused, or that you have shared knowledge of the weakness with others. You can rest assured that an accidental discovery in our online environment will not lead to prosecution.`}</li>
        </ul>
        <h3>{`Exclusions`}</h3>
        <p>{`This Responsible Disclosure scheme is not intended for reporting complaints. The scheme is also not intended for:`}</p>
        <ul>
          <li parentName="ul">{`Reporting that the website is not available.`}</li>
          <li parentName="ul">{`Reporting fake e-mails (phishing e-mails).`}</li>
          <li parentName="ul">{`Reporting fraud.`}</li>
        </ul>
        <p>{`For issues pertaining to the above and any other inquiries please get in touch with our `}<a parentName="p" {...{
            "href": "mailto:support@notifica.re"
          }}>{`support team`}</a>{`.`}</p>
        <h3>{`Rewards / bug bounty`}</h3>
        <p>{`Although Notificare does not have an active bug bounty scheme, we will make an appropriate monetary reward available for reports that actually lead to remedying a vulnerability or a change in our services. We decide whether the report is eligible, and the nature and amount of the remuneration.`}</p>
        <h3>{`Which systems/problems are excluded from rewards?`}</h3>
        <p>{`Not all systems that are accessible under our logos fall under Notificare’s direct control. Although we also take reports regarding these systems very seriously, we cannot allow them to lead to any rewards.`}</p>
        <p>{`We also exclude specific problems that in our opinion do not constitute a threat outside of a laboratory set-up.`}</p>
        <p><strong parentName="p">{`EXCLUDED SYSTEMS`}</strong></p>
        <ul>
          <li parentName="ul">{`notificare.com`}</li>
          <li parentName="ul">{`docs.notifica.re`}</li>
          <li parentName="ul">{`api-docs.notifica.re`}</li>
        </ul>
        <p><strong parentName="p">{`EXCLUDED TYPES OF SECURITY PROBLEMS`}</strong></p>
        <ul>
          <li parentName="ul">{`SPF / DMARC records`}</li>
          <li parentName="ul">{`(D)DOS attacks and rate limiting of calls`}</li>
          <li parentName="ul">{`Problems that amount to self-XSS`}</li>
          <li parentName="ul">{`Error messages without sensitive data`}</li>
          <li parentName="ul">{`Reports from which software we use can be deduced`}</li>
          <li parentName="ul">{`Publicly accessible applicationKey and applicationSecret in website or mobile application config files`}</li>
          <li parentName="ul">{`Problems that require the use of heavily outdated operating systems, browsers or - obsolete plug ins`}</li>
          <li parentName="ul">{`Problems that have already been reported by vulnerability scanning tools like Dependabot and Snyk`}</li>
          <li parentName="ul">{`Problems that are already known to us`}</li>
        </ul>
        <p>{`This policy has been drawn up based on the NCSC’s `}<a parentName="p" {...{
            "href": "https://english.ncsc.nl/publications/publications/2019/juni/01/coordinated-vulnerability-disclosure-the-guideline"
          }}>{`Responsible Disclosure Guideline`}</a>{`.`}</p>
      </Container>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      